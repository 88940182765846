import React from 'react';
import FullWidthImage from '../components/global/full-width-image/full-width-image';
import GridGallery from '../components/global/grid-gallery/grid-gallery';
import HeaderAndText from '../components/global/header-and-text/header-and-text';
import Layout from '../components/global/layout/layout';

export default props => {
    const room = props.pageContext.node.data;
    const galleryData = room.gallery.map(node => {
        return {
            fluid: node.image.localFile.childImageSharp.fluid,
        };
    });

    return (
        <Layout
            wrapperClassName="bg-pattern-gold"
            title={room.meta_title.text}
            titleDivider=" - Räumlichkeiten - "
            titleSuffix="Schloss Westerburg"
            description={room.meta_description.text}
            image={'https://www.schlosswesterburg.de' + room.meta_image.localFile.childImageSharp.original.src}
        >
            <FullWidthImage tag="header" fluid={room.header_image.localFile.childImageSharp.fluid} />
            <HeaderAndText
                heading={room.title.text}
                subtitle={room.subtitle ? room.subtitle.text : undefined}
                text={room.text.raw.map(c => c.text)}
            />
            <GridGallery images={galleryData} />
        </Layout>
    )
}
